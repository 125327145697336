import React, { useContext } from 'react';

import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from '@mui/material';
import { DialogContext, ProjectContext } from '../../../providers';
import { Replay, Warning } from '@mui/icons-material';

type WorkflowDialogProps = {
  title?: string;
  isWarning?: boolean;
  saveFn: () => void;
  resetFn: () => void;
  children: React.ReactNode;
};

export const WorkflowDialog = ({
  title,
  isWarning,
  saveFn,
  resetFn,
  children,
}: WorkflowDialogProps) => {
  const { performingAction } = useContext(ProjectContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { workflowDialog } = dialogs;

  return (
    <Dialog open={workflowDialog} fullScreen>
      <Container>
        {title && (
          <DialogTitle sx={{ paddingLeft: 0, paddingRight: 0 }}>
            {title}
          </DialogTitle>
        )}

        {children}

        <DialogActions
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-end',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {isWarning && (
            <Alert severity={'warning'} sx={{ width: '100%', mb: 2 }}>
              Updating a running workflow may have unintended consequences
            </Alert>
          )}
          <Stack direction="row" spacing={1} mb={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => closeDialog('workflowDialog')}
            >
              Close
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={resetFn}
              endIcon={<Replay />}
            >
              Reset
            </Button>
            <Button
              color={isWarning ? 'warning' : 'primary'}
              startIcon={isWarning ? <Warning /> : null}
              disabled={performingAction}
              variant="contained"
              onClick={saveFn}
            >
              Save & Close
            </Button>
          </Stack>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
