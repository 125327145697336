import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  LeagueEmails,
  LeagueSetup,
  PracticeSchedule,
  TabView,
} from '../../index';
import { DirectionsRun, NoteAlt, Scoreboard } from '@mui/icons-material';
import { Matches } from '../Match';
import { LeagueContext, LeagueProvider } from '../../../providers';
import { Container } from '@mui/material';

export const LeagueWrapper = () => {
  const { league } = useContext(LeagueContext);

  const tabs = [
    { name: 'Setup', icon: <NoteAlt />, component: <LeagueSetup /> },
    { name: 'Matches', icon: <Scoreboard />, component: <Matches /> },
    {
      name: 'Practices',
      icon: <DirectionsRun />,
      component: <PracticeSchedule />,
    },
    {
      name: 'Emails',
      icon: <NoteAlt />,
      component: <LeagueEmails />,
    },
  ];

  if (!league) return <></>;

  return (
    <Container>
      <TabView tabs={tabs} title={league.name!!} />
    </Container>
  );
};

export const League = () => {
  const { leagueId } = useParams();
  const leagueIdNum = Number(leagueId);
  if (!leagueIdNum) return <></>;

  return (
    <LeagueProvider leagueId={leagueIdNum}>
      <LeagueWrapper />
    </LeagueProvider>
  );
};
