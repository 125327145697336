import React, { useContext } from 'react';

import { Route, Routes } from 'react-router-dom';
import {
  AppointmentProvider,
  ChefProvider,
  ProjectContext,
} from '../../../providers';
import { NavDrawer } from '../NavDrawer';
import {
  Bar,
  DialogHost,
  IntercomInstance,
  PingConsumer,
  VersionConsumer,
} from '../../index';
import { Alert, Snackbar } from '@mui/material';

export const Router = () => {
  const { appRoutes, projectName, snackbarData, setSnackbar } =
    useContext(ProjectContext);
  const routerGroup = (
    <>
      <IntercomInstance />
      <PingConsumer />
      <VersionConsumer />
      <Bar />
      <NavDrawer />
      <Routes>
        {appRoutes.map((appRoute, index) => (
          <Route
            key={index}
            path={appRoute.path}
            element={appRoute.component}
          />
        ))}
      </Routes>
      <Snackbar
        autoHideDuration={snackbarData.autoHideDuration}
        open={snackbarData.open}
        onClose={(_event, _reason) => {
          setSnackbar();
        }}
      >
        <Alert
          onClose={(_event) => {
            setSnackbar();
          }}
          severity={snackbarData.severity}
          sx={{ width: '100%' }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
      <DialogHost />
    </>
  );

  if (projectName === 'chef') {
    return <ChefProvider>{routerGroup}</ChefProvider>;
  } else if (projectName === 'pbs') {
    return <AppointmentProvider>{routerGroup}</AppointmentProvider>;
  } else {
    return routerGroup;
  }
};
