import React from 'react';
import { Box, Container } from '@mui/material';
import { AnnouncementList } from './AnnouncementList';
import { useGetAnnouncements } from '../../../api';

export const Announcements = () => {
  const { ...queryInfo } = useGetAnnouncements();

  return (
    <Box mt={2}>
      <Container>
        <AnnouncementList
          announcements={queryInfo.data}
          announcementsAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
