import React, { useContext } from 'react';
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { TypeAnnouncement } from '../../../types';
import { useGetRoles, usePostAnnouncement } from '../../../api';
import { ClearIcon, DateTimePicker } from '@mui/x-date-pickers';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';
import { isValid, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AnnouncementDialog } from '../Dialogs';
import Autocomplete from '@mui/material/Autocomplete';
import { Loader } from '../Loader';

export type AnnouncementProps = {
  announcement: TypeAnnouncement | null;
  setAnnouncement: (_announcement: TypeAnnouncement) => void;
};
export const Announcement = ({
  announcement,
  setAnnouncement,
}: AnnouncementProps) => {
  const { performingAction, setPerformingAction, setSnackbar } =
    useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { closeDialog } = useContext(DialogContext);

  const {
    data: rolesData,
    isLoading: rolesLoading,
    error: rolesError,
  } = useGetRoles();
  const { mutate: announcementMutation } = usePostAnnouncement();

  const handleSave = (announcement: TypeAnnouncement | null) => {
    if (!announcement) return;
    setPerformingAction(true);
    announcementMutation(announcement, {
      onSuccess: () => {
        setSnackbar('Announcement saved');
        closeDialog('announcementDialog');
      },
      onSettled: () => {
        setPerformingAction(false);
      },
    });
  };

  if (rolesLoading || rolesError) {
    return <Loader />;
  }
  return (
    <AnnouncementDialog
      title={announcement?.id ? 'Edit Announcement' : 'Create Announcement'}
      saveFn={() => handleSave(announcement)}
      resetFn={() => {}}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="announcementTitle"
              value={announcement?.announcementTitle || ''}
              label="Enter Announcement Title"
              placeholder="Announcement Title"
              onChange={(event) =>
                setAnnouncement({
                  ...announcement,
                  announcementTitle: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              sx={{ width: '100%' }}
              value={
                announcement?.startAt ? parseISO(announcement?.startAt) : null
              }
              label="Start Date"
              onChange={(value) => {
                console.log(value);
                setAnnouncement({
                  ...announcement,
                  startAt:
                    value && isValid(value)
                      ? formatInTimeZone(
                          value,
                          profile?.timeZone!!,
                          `yyyy-MM-dd'T'HH:mm:ssXXX`,
                        )
                      : undefined,
                });
              }}
              disabled={performingAction}
              componentsProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setAnnouncement({
                              ...announcement,
                              startAt: undefined,
                            })
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              sx={{ width: '100%' }}
              value={announcement?.endAt ? parseISO(announcement?.endAt) : null}
              label="End Date"
              onChange={(value) =>
                setAnnouncement({
                  ...announcement,
                  endAt:
                    value && isValid(value)
                      ? formatInTimeZone(
                          value,
                          profile?.timeZone!!,
                          `yyyy-MM-dd'T'HH:mm:ssXXX`,
                        )
                      : undefined,
                })
              }
              disabled={performingAction}
              componentsProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setAnnouncement({
                              ...announcement,
                              endAt: undefined,
                            })
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={announcement?.role}
              id="roles"
              options={rolesData || []}
              getOptionLabel={(option) => option.authority || ''}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(_event, newValue) =>
                setAnnouncement({
                  ...announcement,
                  role: newValue ?? undefined,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              variant="outlined"
              id="announcementNotes"
              value={announcement?.announcementNotes || ''}
              label="Enter Announcement Notes"
              placeholder="Announcement Notes"
              onChange={(event) =>
                setAnnouncement({
                  ...announcement,
                  announcementNotes: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </Container>
    </AnnouncementDialog>
  );
};
