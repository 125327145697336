import React, { createContext, useMemo, useState } from 'react';

export type DialogContextType = {
  dialogs: TypeDialogStates;
  openDialog: (_dialog: DialogOptions) => void;
  closeDialog: (_dialog: DialogOptions) => void;
};

const DIALOG_STATES_DEFAULT = {
  signUpDialog: false,
  signInDialog: false,
  settingsDialog: false,
  announcementDialog: false,
  cancelAppointmentDialog: false,
  confirmRescheduleDialog: false,
  deleteAccountDialog: false,
  deleteClientDialog: false,
  deleteCoachDialog: false,
  deleteCourseDialog: false,
  deleteDivisionDialog: false,
  deleteLeagueDateDialog: false,
  deleteLeagueFieldDialog: false,
  deleteLeagueTimeDialog: false,
  deleteOrderDialog: false,
  deleteTeamDialog: false,
  signOutDialog: false,
  deleteRecipeDialog: false,
  deleteMaterialDialog: false,
  deleteMeasurementDialog: false,
  deleteDiscussionTopicDialog: false,
  deleteAnnouncementDialog: false,
  claimPracticeAvailabilityDialog: false,
  claimPracticeSlotDayDialog: false,
  releasePracticeSlotDayDialog: false,
  swapPracticeSlotDayDialog: false,
  sendEmailDialog: false,
  taskDialog: false,
  workflowDialog: false,
  documentDialog: false,
};

const DialogContext = createContext<DialogContextType>({
  dialogs: DIALOG_STATES_DEFAULT,
  openDialog: (_dialog) => {},
  closeDialog: (_dialog) => {},
});

type DialogProviderProps = {
  children: React.ReactNode;
};

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}

// type for dialogs object below
type TypeDialogStates = {
  // eslint-disable-next-line no-unused-vars
  [key in DialogOptions]: boolean;
};
// extract the 5 cases in openDialog to a type
type DialogOptions =
  | 'signUpDialog'
  | 'signInDialog'
  | 'settingsDialog'
  | 'announcementDialog'
  | 'cancelAppointmentDialog'
  | 'confirmRescheduleDialog'
  | 'deleteAccountDialog'
  | 'deleteClientDialog'
  | 'deleteCoachDialog'
  | 'deleteCourseDialog'
  | 'deleteDivisionDialog'
  | 'deleteLeagueDateDialog'
  | 'deleteLeagueFieldDialog'
  | 'deleteLeagueTimeDialog'
  | 'deleteOrderDialog'
  | 'deleteTeamDialog'
  | 'signOutDialog'
  | 'deleteRecipeDialog'
  | 'deleteMaterialDialog'
  | 'deleteMeasurementDialog'
  | 'deleteDiscussionTopicDialog'
  | 'deleteAnnouncementDialog'
  | 'claimPracticeAvailabilityDialog'
  | 'claimPracticeSlotDayDialog'
  | 'releasePracticeSlotDayDialog'
  | 'swapPracticeSlotDayDialog'
  | 'sendEmailDialog'
  | 'taskDialog'
  | 'workflowDialog'
  | 'documentDialog';

const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogStates, setDialogStates] = useState<TypeDialogStates>(
    DIALOG_STATES_DEFAULT,
  );

  const openDialog = (dialog: DialogOptions) => {
    setDialogStates({ ...dialogStates, [dialog]: true });
  };

  const closeDialog = (dialog: DialogOptions) => {
    setDialogStates({ ...dialogStates, [dialog]: false });
  };

  const dialogs: TypeDialogStates = useMemo(() => dialogStates, [dialogStates]);

  return (
    <DialogContext.Provider value={{ dialogs, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export { DialogContext, DialogProvider };
