import React, { useContext, useState } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeAnnouncement } from '../../../types';
import { AuthContext, DialogContext } from '../../../providers';
import { DeleteAnnouncementDialog } from '../Dialogs';
import { useDeleteAnnouncement } from '../../../api';
import { DeleteForever } from '@mui/icons-material';
import { formatInTimeZone } from 'date-fns-tz';
import { Announcement } from './Announcement';

export const AnnouncementList = ({
  announcements,
  announcementsAvailable,
}: {
  announcements: TypeAnnouncement[] | undefined;
  announcementsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { profile } = useContext(AuthContext);
  const { mutate: deleteAnnouncement } = useDeleteAnnouncement();
  const [announcementToDelete, setAnnouncementToDelete] =
    useState<TypeAnnouncement>();
  const [editAnnouncement, setEditAnnouncement] =
    useState<TypeAnnouncement | null>(null);

  const handleOpenAnnouncementDialog = (announcement: TypeAnnouncement) => {
    setEditAnnouncement(announcement);
    openDialog('announcementDialog');
  };

  const handleDeleteAnnouncementDialog = (announcement?: TypeAnnouncement) => {
    if (announcement) {
      setAnnouncementToDelete(announcement);
      openDialog('deleteAnnouncementDialog');
    } else {
      setAnnouncementToDelete(undefined);
      closeDialog('deleteAnnouncementDialog');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            onClick={() => handleOpenAnnouncementDialog({})}
          >
            Create Announcement
          </Button>
        </Grid>
      </Grid>
      <TableContainer hidden={!announcementsAvailable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Announcement</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements?.map((announcement) => (
              <TableRow key={announcement.id}>
                <TableCell>
                  <Button
                    onClick={() => handleOpenAnnouncementDialog(announcement)}
                  >
                    {announcement.announcementTitle}
                  </Button>
                </TableCell>
                <TableCell>
                  {announcement.startAt
                    ? formatInTimeZone(
                        announcement.startAt,
                        profile?.timeZone!!,
                        `MMM do, yyyy - hh:mmaaa`,
                      )
                    : 'No Start Date'}
                </TableCell>
                <TableCell>
                  {announcement.endAt
                    ? formatInTimeZone(
                        announcement.endAt,
                        profile?.timeZone!!,
                        `MMM do, yyyy - hh:mmaaa`,
                      )
                    : 'No End Date'}
                </TableCell>
                <TableCell>{announcement.role?.authority || 'N/A'}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteAnnouncementDialog(announcement)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {announcements?.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>No Announcements Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {announcementToDelete ? (
        <DeleteAnnouncementDialog
          announcementTitle={announcementToDelete.announcementTitle!!}
          deleteFn={() =>
            deleteAnnouncement(announcementToDelete, {
              onSuccess: () => handleDeleteAnnouncementDialog(),
            })
          }
        />
      ) : null}
      <Announcement
        announcement={editAnnouncement}
        setAnnouncement={setEditAnnouncement}
      />
    </>
  );
};
