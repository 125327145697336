import React from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

interface TimePickerWithZoneProps {
  value: string | null; // Expects format like "09:00-05:00"
  onChange: (_newTime: string | null) => void;
  userTimeZone: string; // e.g. "America/New_York"
  label?: string;
}

export const TimePickerWithZone = ({
  value,
  onChange,
  userTimeZone,
  label = 'Select Time',
}: TimePickerWithZoneProps) => {
  // Convert from OffsetTime string to Date for TimePicker
  const parseOffsetTimeToDate = (timeString: string | null): Date | null => {
    if (!timeString) return null;

    try {
      // Extract time part before offset
      const timeOnly = timeString.split(/[+-]/)[0];
      const [hours, minutes] = timeOnly.split(':');

      // Create date object for today with the time
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      date.setSeconds(0);

      return date;
    } catch (error) {
      console.error('Error parsing time:', error);
      return null;
    }
  };

  // Convert from Date back to OffsetTime string
  const formatDateToOffsetTime = (date: Date | null): string | null => {
    if (!date) return null;

    try {
      // Get time in user's timezone
      const timeInZone = utcToZonedTime(date, userTimeZone);

      // Get timezone offset
      const offset = format(timeInZone, 'xxx'); // Returns like '-05:00'

      // Format time and append offset
      const timeStr = format(timeInZone, 'HH:mm');
      return `${timeStr}${offset}`;
    } catch (error) {
      console.error('Error formatting time:', error);
      return null;
    }
  };

  return (
    <TimePicker
      sx={{ width: '100%' }}
      label={label}
      value={parseOffsetTimeToDate(value)}
      onChange={(newValue) => {
        onChange(formatDateToOffsetTime(newValue));
      }}
      views={['hours', 'minutes']}
      format="h:mm a"
      ampm
      slotProps={{
        textField: {
          helperText: `Times are shown in ${userTimeZone}`,
        },
      }}
    />
  );
};
